import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { Markup } from 'interweave';
const userId = localStorage.getItem('userId');

const Message = (props) => {
    const date = new Date();
    const utcOffset = date.getTimezoneOffset();
    const dtsDate = new Date(props.dts);
    const offset = dtsDate.getTimezoneOffset();
    const minutes = utcOffset - offset;
    const users = props.users;
    const [ userId, setUserId ] = useState(props.transferUserId);
    const [ status, setStatus ] = useState(props.status);
    function UpdateAutoChat(status, userId, chatId){
        try {
            setStatus(status);
            setUserId(userId);
            fetch('https://api.leadmanagertraining.com/api/chat/updatechat?status=' + status + '&userId=' + userId + '&chatId=' + chatId, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
        catch(e) {
            console.log('Sending message failed.', e);
        }
    }

    return(
    <Alert key='dark' variant={props.userId == userId ? 'primary' : 'dark'}>
        <p><strong>{props.user}</strong> says:<small style={{position: 'absolute', right: 0}}>{moment(props.dts).format('MM/DD/YYYY h:mm a')}</small></p>
        <p><Markup content={props.message} /></p>
        {props.userId == '587' &&
        
        <div>
            <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control className="form-select" as="select" placeholder="Select Status" onChange={(e) => UpdateAutoChat(e.target.value, userId, props.id)} defaultValue={props.status}>
                    <option></option>
                    <option>Quoted</option>
                    <option>One-Call-Close</option>
                    <option>Failed Transfer</option>
                    <option>Did Not Qualify</option>
                    <option>Sold</option>
                    <option>Missed Call</option>
                    <option>Not Interested</option>
                    <option>Return Lead</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Transferred To</Form.Label>
                <Form.Control className="form-select" as="select" placeholder="Select User" onChange={(e) => UpdateAutoChat(status, e.target.value, props.id)} defaultValue={props.transferUserId}>
                    <option></option>
                    {
                        users.filter(i => i.status != 4).map(u => 
                            <option value={u.id}>{u.firstName + ' ' + u.lastName}</option>
                        
                        )
                    }
                </Form.Control>
            </Form.Group>
        </div>
        }
    </Alert>
    )
};

export default Message;