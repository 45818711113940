import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { BsPhone } from 'react-icons/bs';

class AutoQuoteFormConfirmation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        };
    }

    render() {
        return (
            <div className="container">
                <Image src="FullCircleLogo.jpeg" fluid />
                <div style={{textAlign: 'center'}}>
                    <h1>Thank you for your submission!!  We are working on your quote.</h1>
                    <a className='btn btn-primary btn-lg rounded-pill'  href='tel:7704065829' target="_blank" style={{marginTop: 20}}>
                        <BsPhone /> CALL FOR IMMEDIATE QUOTE
                    </a>
                </div>
                
            </div>
        );
    }
}
export default AutoQuoteFormConfirmation;